import styled from "@emotion/styled";
import { motion } from "framer-motion";

interface NextButtonProps {
  onClick?: () => any;
}

const ContainerVariants = {
  idle: { scale: 1 },
  hover: { scale: 1.05 },
  tap: { scale: 0.98 },
};
const Container = styled(motion.button)`
  width: 60px;
  height: 60px;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
`;

const BorderVariants = {
  idle: { backgroundColor: "rgba(255,255,255,0)" },
  hover: { backgroundColor: "var(--btnBorder)" },
};
const Border = styled(motion.div)`
  width: 100%;
  height: 100%;
  border: 2px solid var(--btnBorder);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const IconVariants = {
  idle: { color: "var(--btnIconIdle)" },
  hover: { color: "var(--btnIconHover)" },
};
const Icon = styled(motion.div)`
  font-family: "made_outer_sans_alt_medium";
  font-size: 36px;
  line-height: 20px;
  /* or 56% */
  text-align: center;
  letter-spacing: -0.05em;
  color: var(--btnIconIdle);
  margin-bottom: 4px;
`;

const NextButton = function ({onClick}: NextButtonProps) {
  return (
    <Container onClick={onClick} whileHover="hover" whileTap="tap" variants={ContainerVariants}>
      <Border variants={BorderVariants}>
        <Icon variants={IconVariants}>{">"}</Icon>
      </Border>
    </Container>
  );
};

export default NextButton;

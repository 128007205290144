import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import Card from "./Card";

const Container = styled.div`
  width: 100%;
`;

const variants = {
  none: {
    transform: "rotate(0deg) scale(1) translate(0%)",
  },
  top: {
    transform: "rotate(-5deg) scale(1) translate(0%)",
  },
  left: {
    transform: "rotate(-10deg) scale(0.6) translate(-120%)",
  },
};
const FlipperContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
`;

interface CardFlipperProps {
  username: string;
  handle: string;
  imgUrl: string;
  tweets: string[];
}

const CardFlipper = function ({
  username,
  handle,
  imgUrl,
  tweets,
}: CardFlipperProps) {
  const [renderTweets, setRenderTweets] = useState(tweets);
  const [state, setState] = useState("INITIAL");

  useEffect(() => {
    const timings = {
        "INITIAL" : 1750,
        "LEFT": 120,
        "RETURN": 0,
        "RETURN_MOVE": 1750,
    }
    const advance = function () {
      if (state === "INITIAL") {
        setState("LEFT");
      }
      if (state === "LEFT") {
        const currTopTweet = renderTweets[renderTweets.length - 1];
        const before = renderTweets.splice(0, renderTweets.length - 1);
        const newTweets = [currTopTweet, ...before];
        setState("RETURN");
        setRenderTweets(newTweets);
      }
      if (state === "RETURN") {
        setState("RETURN_MOVE")
      }
      if (state === "RETURN_MOVE") {
        setState("INITIAL");
      }
    };
    // @ts-ignore : TODO: add type to state
    const id = setTimeout(advance, timings[state]);
    return () => clearTimeout(id);
  }, [state, renderTweets, setRenderTweets]);

  return (
    <Container>
      {renderTweets.map((tweet, i) => {
        var init = "none";
        var anim = "none";
        if (i === 0){
            if (state === "RETURN"){
                init = "left";
                anim = "left";
            }
            else if (state === "RETURN_MOVE"){
                init = "left";
                anim = "none";
            }
        }
        else if (i === renderTweets.length - 1) {
            if (state === "INITIAL"){
                anim = "top";
                init = "top";
            }
            else if (state === "LEFT") {
                init = "top";
                anim = "left";
            }
            else if (state === "RETURN"){
                init = "none";
                anim = "top";
            }
            else if (state === "RETURN_MOVE"){
                init = "top";
                anim = "top";
            }
        }
        return (
          <FlipperContainer
            variants={variants}
            initial={init}
            animate={anim}
            key={tweet}
          >
            <Card
              username={username}
              handle={handle}
              imgUrl={imgUrl}
              tweet={tweet}
            />
          </FlipperContainer>
        );
      })}
    </Container>
  );
};

export default CardFlipper;

import styled from "@emotion/styled";
import { useQuery } from "@tanstack/react-query";
import { User } from "firebase/auth";
import { motion } from "framer-motion";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { get_tweet_generations } from "../api/routes";
import Card, { MIN_HEIGHT } from "../components/Card";
import CardFlipper from "../components/CardFlipper";
import NextButton from "../components/NextButton";
import Spacer from "../components/Spacer";

const BackgroundVariants = {
  "0": {
    background: "var(--mirrorBg1)",
  },
  "1": {
    background: "var(--mirrorBg2)",
  },
  "2": {
    background: "var(--bg)",
  },
};
const Background = styled(motion.div)`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: var(--mirrorBg1);
`;

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Container = styled.div`
  height: 100%;
  width: 390px;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 24px;
  z-index: 2;
`;

const FadeInEnterExitVariants = {
  initial: {
    opacity: 0.0,
  },
  animate: {
    opacity: 1.0,
  },
};

const Logo = styled(motion.div)`
  font-family: "made_outer_sans_alt_light";
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.05em;
  color: var(--textPrimary);
  margin-top: 60px;
`;

const Title = styled(motion.div)`
  font-family: "made_outer_sans_bold";
  font-size: 40px;
  line-height: 51px;
  letter-spacing: -0.05em;
  color: #643440;
`;

const NextButtonContainer = styled(motion.div)`
  align-self: center;
`;

const ChartContainer = styled.div`
  width: 300px;
  display: flex;
  flex-direction: row;
  padding-right: 12px;
`;

const ChartAxis = styled.div`
  writing-mode: vertical-rl;
  font-family: "made_outer_sans_light";
  font-size: 16px;
  line-height: 125%;
  letter-spacing: -0.05em;
  color: var(--textPrimary);
  text-align: center;
`;

const ChartDivider = styled.div`
  width: 2px;
  margin-left: 8px;
  background-color: var(--bg);
`;

const ITEM_HEIGHT = 60;
const ITEM_SPACING = 12;

const ChartItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: ${ITEM_SPACING}px;
`;

const ChartItemContainer = styled.div`
  width: 100%;
  height: ${ITEM_HEIGHT}px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ChartItem = styled(motion.div)`
  height: 100%;
  background: linear-gradient(90deg, #d9d9d9 0%, rgba(217, 217, 217, 0) 100%);
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const ChartItemLabel = styled.p`
  font-family: "made_outer_sans_light";
  font-size: 16px;
  line-height: 125%;
  letter-spacing: -0.05em;
  color: var(--textPrimary);
  margin-left: 12px;
  position: absolute;
  left: calc(100%);
`;

const Caption = styled.div`
  font-family: "made_outer_sans_light";
  font-size: 16px;
  line-height: 20px;
  /* or 125% */
  text-align: center;
  letter-spacing: -0.05em;
  color: var(--textPrimary);
`;

interface MirrorProps {
  user: User;
}

const Mirror = function ({ user }: MirrorProps) {
  // Process user
  // @ts-ignore This property isn't in the typing but is internal and we need it
  var username = user["reloadUserInfo"]["screenName"];
  // @ts-ignore This property isn't in the typing but is internal and we need it
  var photoUrl = user["photoURL"];
  var displayName = user["displayName"];
  //username = "andy_matuschak";

  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  // Call API retrieve their generations, if any
  const { isLoading, isSuccess, error, data } = useQuery({
    queryKey: ["get_tweet_generations"],
    queryFn: () =>
      fetch(get_tweet_generations, {
        method: "POST",
        body: JSON.stringify({ username: username }),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => res.json()),
    cacheTime: 0,
  });

  useEffect(() => {
    if (isSuccess && !data.has_generations){
      navigate('/train');
    }
  },  [isSuccess, data, navigate]);

  // Wait out load
  if (isLoading) {
    return null;
  }

  // Handle authenticated but not ready user
  if (isSuccess && !data.has_generations){
    return null;
  }

  if (error){
    console.error('Error fetching generations');
    return null;
  }

  // Process most liked
  const most_liked = Math.max(...data.most_liked.map((x: any[]) => x[1]));
  const tweets = data.most_liked.map((x: any[]) => x[0]);

  return (
    <>
      <Background
        variants={BackgroundVariants}
        initial={false}
        animate={page.toString()}
      >
        {page === 0 && isSuccess && (
          <Page0
            numTweets={data.tweet_count}
            topics_arr={data.topics.slice(0, 4)}
            advancePage={() => setPage(1)}
          />
        )}
        {page === 1 && (
          <Page1
            username={username}
            photoUrl={photoUrl ?? ""}
            displayName={displayName ?? "Tweeter"}
            mostLiked={most_liked}
            tweets={tweets}
            advancePage={() => setPage(2)}
          />
        )}
        {page === 2 && (
          <Page3
            username={username}
            photoUrl={photoUrl ?? ""}
            displayName={displayName ?? "Tweeter"}
            tweets={data.tweets}
            advancePage={() => setPage(3)}
          />
        )}
      </Background>
    </>
  );
};

interface Page0Props {
  numTweets: number;
  topics_arr: [string, number][];
  advancePage: () => any;
}

const Page0 = function ({ numTweets, topics_arr, advancePage }: Page0Props) {
  const renderNumTweets = numTweets.toLocaleString("en-US", {
    maximumFractionDigits: 0,
  });
  return (
    <PageContainer>
      <Container>
        <Logo
          variants={FadeInEnterExitVariants}
          initial="initial"
          animate="animate"
        >
          Tweet Mirror
        </Logo>
        <Spacer size={60} />
        <Title
          variants={FadeInEnterExitVariants}
          initial="initial"
          animate="animate"
        >
          You've tweeted {renderNumTweets} times!
        </Title>
        <Spacer size={48} />
        <ChartContainer>
          <ChartAxis>A lot on your mind?</ChartAxis>
          <ChartDivider />
          <ChartItemsContainer>
            {topics_arr.map(([topic, score]) => (
              <ChartItemContainer key={topic}>
                <ChartItem
                  initial={{ width: "0%" }}
                  animate={{ width: `${score * 100}%` }}
                  transition={{ duration: 1.0 }}
                >
                  <ChartItemLabel>{topic}</ChartItemLabel>
                </ChartItem>
              </ChartItemContainer>
            ))}
          </ChartItemsContainer>
        </ChartContainer>
        <Spacer size={48} />
        <Caption>
          These topics and words came up frequently in your tweets. The AI
          learned from what you tweet about.
        </Caption>
        <Spacer size={48} />
        <NextButtonContainer
          variants={FadeInEnterExitVariants}
          initial="initial"
          animate="animate"
        >
          <NextButton onClick={() => advancePage()} />
        </NextButtonContainer>
        <Spacer size={72} />
      </Container>
    </PageContainer>
  );
};

const AudienceTitle = styled(motion.div)`
  font-family: "made_outer_sans_bold";
  font-size: 40px;
  line-height: 51px;
  letter-spacing: -0.05em;
  color: var(--textPrimary);
`;

const CardContainer = styled(motion.div)`
  width: 320px;
  min-height: ${MIN_HEIGHT}px;
  position: relative;
`;

interface Page1Props {
  username: string;
  displayName: string;
  photoUrl: string;
  tweets: string[];
  mostLiked: number;
  advancePage: () => any;
}

const Page1 = function ({
  username,
  displayName,
  photoUrl,
  tweets,
  advancePage,
  mostLiked,
}: Page1Props) {
  const mostLikedDisplay = mostLiked.toLocaleString("en-US", {
    maximumFractionDigits: 0,
  });

  return (
    <PageContainer>
      <Container>
        <Logo
          variants={FadeInEnterExitVariants}
          initial="initial"
          animate="animate"
        >
          Tweet Mirror
        </Logo>
        <Spacer size={12} />
        <AudienceTitle
          variants={FadeInEnterExitVariants}
          initial="initial"
          animate="animate"
        >
          You
          <br />
          know
          <br />
          your
          <br />
          audience!
          <br />
        </AudienceTitle>
        <Spacer size={48} />
        <CardContainer
          variants={FadeInEnterExitVariants}
          initial="initial"
          animate="animate"
        >
          <CardFlipper
            username={username}
            handle={displayName}
            imgUrl={photoUrl}
            tweets={tweets}
          />
        </CardContainer>
        <Spacer size={48} />
        <Caption>
          These are some of your most liked tweets. One was liked{" "}
          {mostLikedDisplay} times! The AI learned from what your followers
          liked.
        </Caption>
        <Spacer size={48} />
        <NextButtonContainer
          variants={FadeInEnterExitVariants}
          initial="initial"
          animate="animate"
        >
          <NextButton onClick={() => advancePage()} />
        </NextButtonContainer>
        <Spacer size={72} />
      </Container>
    </PageContainer>
  );
};

const Gradient = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 800px;
  background: var(--gradientBg);
`;

const IntroTextVariants = {
  initial: {
    transform: "translateY(100%)",
    opacity: 0,
  },
  animate: {
    transform: "translateY(0%)",
    opacity: 1,
    transition: {
      duration: 1.0,
    },
  },
  out: {
    transform: "translateY(100%)",
    opacity: 0,
    transition: {
      duration: 1.0,
    },
  },
};
const IntroText = styled(motion.div)`
  font-family: "made_outer_sans_regular";
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.05em;
  color: var(--textPrimary);
`;

const UsernameSpan = styled.span`
  color: var(--textUsername);
`;

const CardPositioner = styled(motion.div)`
  position: absolute;
`;

interface Page3Props {
  username: string;
  displayName: string;
  photoUrl: string;
  tweets: string[];
  advancePage: () => any;
}

function getRandomSubarray(arr: any[], size: number) {
  var shuffled = arr.slice(0),
    i = arr.length,
    temp,
    index;
  while (i--) {
    index = Math.floor((i + 1) * Math.random());
    temp = shuffled[index];
    shuffled[index] = shuffled[i];
    shuffled[i] = temp;
  }
  return shuffled.slice(0, size);
}

const Page3 = function ({
  username,
  displayName,
  photoUrl,
  tweets,
  advancePage,
}: Page3Props) {
  const [exitIntroText, setExitIntroText] = useState(false);
  const [doDrop, setDoDrop] = useState(false);
  const [doneDrop, setDoneDrop] = useState(false);
  const navigate = useNavigate();
  const tweetsSubset = useMemo(() => {
    return getRandomSubarray(tweets, 15);
  }, [tweets]);

  useEffect(() => {
    if (doneDrop){
      navigate('/generations/' + username);
    }
  }, [doneDrop, navigate, username])

  const coords = [
    [-20, 50],
    [-10, 60],
    [10, 100],
    [10, 125],
    [-5, 140],
    [15, 155],
    [-15, 160],
    [0, 175],
    [10, 200],
    [-15, 220],
    [10, 230],
    [-5, 250],
    [3, 255],
    [10, 265],
    [-10, 270],
  ];
  const rots = [10, 20, -5, 20, -2.5, 20, -10, 0, 20, -15, 5, 10, -15, 0, 10];
  const delays = [0];
  for (var i = 1; i < tweetsSubset.length; i++) {
    const prev = delays[i - 1];
    var delay = 0;
    if (i < tweetsSubset.length / 3) {
      delay = prev + 3;
    } else if (i < tweetsSubset.length / 2) {
      delay = prev + 2;
    } else if (i < tweetsSubset.length / 1.5) {
      delay = prev + 1;
    } else {
      delay = prev + 0.25;
    }
    delays.push(delay);
  }

  return (
    <PageContainer>
      <Gradient variants={FadeInEnterExitVariants} />
      <Container>
        <Logo>Tweet Mirror</Logo>
        <Spacer size={96} />
        <IntroText
          variants={IntroTextVariants}
          onAnimationComplete={() =>
            setTimeout(() => setExitIntroText(true), 2000)
          }
          initial="initial"
          animate={exitIntroText ? "out" : "animate"}
        >
          OK. Let's see what bot <UsernameSpan>@{username}</UsernameSpan> sounds
          like.
        </IntroText>
        <div style={{ position: "relative" }}>
          {tweetsSubset.map((tweet, i) => {
            const variant = {
              initial: {
                opacity: 0,
                transform: `scale(0%) translateX(${coords[i][0]}px) translateY(${coords[i][1]}px) rotate(${rots[i]}deg)`,
              },
              on: {
                opacity: 1,
                transform: `scale(100%) translateX(${coords[i][0]}px) translateY(${coords[i][1]}px) rotate(${rots[i]}deg)`,
                transition: {
                  delay: delays[i],
                },
              },
              out: {
                transform: `scale(100%) translateX(${coords[i][0]}px) translateY(${coords[i][1]+2000}px) rotate(${rots[i]}deg)`,
                opacity: 1,
                transition: {
                  duration: 2,
                  delay: delays.slice().reverse()[i] / 9,
                },
              },
            };
            return (
              <CardPositioner
                variants={variant}
                key={tweet}
                initial={doDrop ? "on" : "initial"}
                style={{display: doneDrop ? "none": "inherit"}}
                animate={exitIntroText ? (doDrop ? "out" : "on") : "initial"}
                onAnimationComplete={() => {
                  if (exitIntroText && i === tweetsSubset.length - 1){
                    setDoDrop(true);
                  } if (doDrop && i === 0){
                    setDoneDrop(true);
                  }
                }}
              >
                <CardContainer
                  variants={FadeInEnterExitVariants}
                  initial="initial"
                  animate="animate"
                >
                  <Card
                    username={username}
                    handle={displayName}
                    imgUrl={photoUrl}
                    tweet={tweet}
                    enforceMinHeight={false}
                  />
                </CardContainer>
              </CardPositioner>
            );
          })}
        </div>
      </Container>
    </PageContainer>
  );
};

export default Mirror;

import { Canvas } from "@react-three/fiber";
import { Bounds, ContactShadows } from "@react-three/drei";
import MirrorLogo from "./MirrorLogo";
import {
  EffectComposer,
  Bloom,
  Noise,
  ChromaticAberration,
} from "@react-three/postprocessing";
import { BlendFunction } from "postprocessing";

interface MirrorLogoStagedProps {
  showBird: boolean;
  margin: number;
  imgSrc?: string;
}

const MirrorLogoStaged = function ({showBird, margin, imgSrc}: MirrorLogoStagedProps) {
    return (
    <Canvas shadows dpr={[1, 2]}>
      <ambientLight intensity={0.25} />
      {/* <pointLight position={[10, 10, 10]} /> */}
      <spotLight
        intensity={1}
        angle={0.2}
        penumbra={1}
        position={[30, 30, 30]}
        castShadow
        shadow-mapSize={[512, 512]}
      />
      <directionalLight
        intensity={1}
        position={[-10, -20, -10]}
        color="white"
      />
      <Bounds fit clip observe margin={margin}>
        <MirrorLogo showBird={showBird} imgSrc={imgSrc}/>
        <ContactShadows
          rotation={[Math.PI / 2, 0, 0]}
          position={[-0.1, -2, 0]}
          opacity={1.0}
          width={1}
          height={1}
          blur={2.8}
          far={10}
          color={"#aa00ff"}
        />
      </Bounds>
      <EffectComposer>
        <Noise opacity={0.08} />
        <Bloom luminanceThreshold={0} luminanceSmoothing={0.9} />
        <ChromaticAberration
          blendFunction={BlendFunction.NORMAL} // blend mode
          // @ts-ignore
          offset={[0.001, 0.002]} // color offset
        />
      </EffectComposer>
    </Canvas>
  );
};

export default MirrorLogoStaged;

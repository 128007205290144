import styled from "@emotion/styled";
import { initializeApp } from "firebase/app";
import { getAuth, User } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./App.css";
import Home from "./pages/Home";
import Train from "./pages/Train";
import PaymentRedirect from "./pages/PaymentRedirect";
import Mirror from "./pages/Mirror";
import { AnimatePresence } from "framer-motion";
import Generations from "./pages/Generations";
import RequireAuth from "./components/RequireAuth";

const AppContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const FIREBASE_CONFIG = {
  apiKey: "AIzaSyDbXQ88E52I4GsI8ywNpanroJABTM_qMyc",
  authDomain: "tweetmirror.firebaseapp.com",
  projectId: "tweetmirror",
  storageBucket: "tweetmirror.appspot.com",
  messagingSenderId: "601259972704",
  appId: "1:601259972704:web:c8076882b065c3c27d8b05",
  measurementId: "G-3B5J4DNM0R",
};
const app = initializeApp(FIREBASE_CONFIG);
const auth = getAuth(app);
const queryClient = new QueryClient();

function App() {
  const [user, loading, error] = useAuthState(auth);

  if (error){
    console.error("Authentication Failed");
  }

  return (
    <QueryClientProvider client={queryClient}>
      <AppContainer>
        <Router>
          <Routes>
            <Route path="/" element={<Home auth={auth} />} />
            <Route
              path="/train"
              element={
                <RequireAuth user={user} authLoading={loading}>
                  <Train user={user as User} />
                </RequireAuth>
              }
            />
            <Route path="/payment/:status" element={<PaymentRedirect />} />
          </Routes>
          <AnimatePresence>
            <Routes>
              <Route
                path="/mirror"
                element={
                  <RequireAuth user={user} authLoading={loading}>
                    {/* RequireAuth enforces user to exist */}
                    <Mirror user={user as User} />
                  </RequireAuth>
                }
              />
              <Route path="/generations/:username" element={<Generations />} />
            </Routes>
          </AnimatePresence>
        </Router>
      </AppContainer>
    </QueryClientProvider>
  );
}

export default App;

import React, { useEffect, useRef } from "react";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { LayerMaterial, Depth, Fresnel } from "lamina";
import * as THREE from "three";
import { useFrame, ThreeElements, useThree } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";
import { Layers } from "three";
import MirrorImage from "./MirrorImage";

type MirrorGLTF = GLTF & {
  nodes: {
    Circle: THREE.Mesh;
    Mirror_Face: THREE.Mesh;
  };
};

type BirdGLTF = GLTF & {
  nodes: {
    base: THREE.Mesh;
    wing1: THREE.Mesh;
    wing2: THREE.Mesh;
  };
};

interface MirrorLogoProps {
  showBird: boolean;
  imgSrc?: string;
}

const MirrorLogo = function (props: ThreeElements["mesh"] & MirrorLogoProps) {
  const layerMatRef = useRef<typeof LayerMaterial>();
  const groupRef = useRef<THREE.Group>(null);
  const birdWing1Ref = useRef<THREE.Mesh>(null);
  const birdWing2Ref = useRef<THREE.Mesh>(null);
  // Shadow renderer renders layer0 only, move bird to layer 1 to avoid it rendering a shadow
  const birdLayers = new Layers();
  birdLayers.disableAll();
  birdLayers.enable(1);
  const { camera } = useThree();

  useEffect(() => {
    const renderLayers = new Layers();
    renderLayers.enable(1);
    camera.layers = renderLayers;
    camera.near = 0.00001;
  }, [camera, camera.layers]);

  const { nodes } = useGLTF("/mirror.glb") as unknown as MirrorGLTF;
  const { nodes: birdNodes } = useGLTF("./twitter.glb") as unknown as BirdGLTF;
  const gradient = 0.2;

  // Animate gradient
  useFrame((state) => {
    // anim gradient
    const sin = Math.sin(state.clock.elapsedTime / 2);
    const cos = Math.cos(state.clock.elapsedTime / 2);
    if (layerMatRef.current) {
      //@ts-ignore
      layerMatRef.current.layers[0].origin.set(cos / 2, 0, 0);
      //@ts-ignore
      layerMatRef.current.layers[1].origin.set(cos, sin, cos);
      //@ts-ignore
      layerMatRef.current.layers[2].origin.set(sin, cos, sin);
      //@ts-ignore
      layerMatRef.current.layers[3].origin.set(cos, sin, cos);

      //anim pos/rot
      const t = state.clock.getElapsedTime();
      if (groupRef.current) {
        groupRef.current.rotation.x = Math.cos(t / 4) / 8;
        groupRef.current.rotation.y =  -Math.PI / 2 + 0.9 + (Math.sin(t / 4) / 8);
        groupRef.current.rotation.z = 0.3 + ((Math.sin(t / 1.5)) / 20);
        groupRef.current.position.y = (1 + Math.sin(t / 1.5)) / 20;
      }

      // Wing rots
      const scaledT = 20 * t;
      const wingBound = 0.6;
      if (birdWing1Ref.current) {
        birdWing1Ref.current.rotation.z = Math.sin(scaledT) * wingBound;
      }
      if (birdWing2Ref.current) {
        birdWing2Ref.current.rotation.z = Math.sin(scaledT) * -wingBound;
      }
    }
  });

  return (
    <>
      <group
        scale={1}
        rotation={[0, -Math.PI / 2 + 0.9, 0.3]}
        position={[-0.5, 0, 0]}
        ref={groupRef}
      >
        <mesh
          {...props}
          scale={1}
          rotation={[0, 0, 0]}
          geometry={nodes.Circle.geometry}
          receiveShadow
        >
          <meshStandardMaterial
            color="#b966ed"
            roughness={0.5}
            emissive={"#370037"}
            emissiveIntensity={2}
            toneMapped={false}
          />
        </mesh>
        <mesh
          {...props}
          scale={1}
          rotation={[0, 0, 0]}
          geometry={nodes.Mirror_Face.geometry}
          receiveShadow
        >
          {/* @ts-ignore */}
          <LayerMaterial ref={layerMatRef} toneMapped={false}>
            <Depth
              colorA="#7E9262"
              colorB="black"
              alpha={1}
              mode="normal"
              near={0.5 * gradient}
              far={0.5}
              origin={[0, 0, 0]}
            />
            <Depth
              colorA="#3067CF"
              colorB="#721C65"
              alpha={1}
              mode="add"
              near={2 * gradient}
              far={2}
              origin={[0, 1, 1]}
            />
            <Depth
              colorA="#d20dd9"
              colorB="black"
              alpha={1}
              mode="add"
              near={3 * gradient}
              far={3}
              origin={[0, 1, -1]}
            />
            <Depth
              colorA="white"
              colorB="red"
              alpha={1}
              mode="overlay"
              near={1.5 * gradient}
              far={1.5}
              origin={[1, -1, -1]}
            />
            <Fresnel
              mode="add"
              color="white"
              intensity={0.5}
              power={1.5}
              bias={0.05}
            />
          </LayerMaterial>
        </mesh>
        { /* Image Profile */ }
        {props.imgSrc && <MirrorImage imgSrc={props.imgSrc}/>}
        {/* Bird */}
        {props.showBird && (
          <group
            scale={15}
            position={[1.25, -0.2, -0.2]}
            rotation={[-0.3, (Math.PI/2 + 1.2), 0]}
            layers={birdLayers}
          >
            <mesh
              receiveShadow
              geometry={birdNodes.base.geometry}
              position={[0.01, 0.02, 0]}
              rotation={[Math.PI / 2, 0, 0]}
              layers={birdLayers}
            >
              <meshStandardMaterial
                color="#27568b"
                roughness={0.5}
                emissive={"#370037"}
                emissiveIntensity={2}
                toneMapped={false}
              />
            </mesh>
            <mesh
              receiveShadow
              geometry={birdNodes.wing1.geometry}
              position={[-0.007, 0.01, 0]}
              rotation={[Math.PI / 2, -0.47, Math.PI / 4]}
              ref={birdWing1Ref}
              layers={birdLayers}
            >
              <meshStandardMaterial
                color="#27568b"
                roughness={0.5}
                emissive={"#370037"}
                emissiveIntensity={2}
                toneMapped={false}
              />
            </mesh>
            <mesh
              receiveShadow
              geometry={birdNodes.wing2.geometry}
              position={[-0.007, 0.01, 0]}
              rotation={[Math.PI / 2, -0.47, -Math.PI / 4]}
              ref={birdWing2Ref}
              layers={birdLayers}
            >
              <meshStandardMaterial
                color="#27568b"
                roughness={0.5}
                emissive={"#370037"}
                emissiveIntensity={2}
                toneMapped={false}
              />
            </mesh>
          </group>
        )}
      </group>
    </>
  );
};

export default MirrorLogo;

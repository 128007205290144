import styled from "@emotion/styled";

export const MIN_HEIGHT = 260;

interface ContainerProps {
  useMinHeight: boolean;
  stack: boolean;
}

const Container = styled.div<ContainerProps>`
  width: 100%;
  min-height: ${(props) => props.useMinHeight ? MIN_HEIGHT : 0}px;
  position: ${(props) => props.stack ? 'absolute' : 'inherit'};
  padding-bottom: 12px;
`;

const Background = styled.div`
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border-radius: 12px;
  background: #191514;
  position: absolute;
  top: 1px;
  left: 1px;
`;

const Border = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: linear-gradient(120deg, #745077, #295a6a);
  position: absolute;
  top: 0px;
`;

const ProfileImg = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  position: relative;
  margin-top: 12px;
  margin-left: 12px;
`;

const UserContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`;

const UserNameHandleContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 13px;
    margin-bottom: 0px;
`; 

const Username = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: var(--textPrimary);
  margin-top: -3px;
  margin-left: 8px;
  margin-bottom: 0px;
`;

const Handle = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: var(--textPrimary);
  margin-top: 0px;
  margin-left: 8px;
  margin-bottom: 0px;
`;

const Body = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: -0.02em;
  color: var(--textPrimary);
  position: relative;
  margin-top: 12px;
  margin-left: 12px;
  margin-right: 12px;
  overflow-wrap: break-word;
`;

interface CardProps {
  username: string;
  handle: string;
  tweet: string;
  imgUrl: string;
  enforceMinHeight?: boolean;
  stack?: boolean;
}

const Card = function ({username, handle, tweet, imgUrl, enforceMinHeight = true, stack=true}: CardProps ) {
  return (
    <Container useMinHeight={enforceMinHeight} stack={stack}>
      <Border />
      <Background />
      <UserContainer>
        <ProfileImg src={imgUrl}/>
        <UserNameHandleContainer>
            <Username>{username}</Username>
            <Handle>@{handle}</Handle>
        </UserNameHandleContainer>
      </UserContainer>
      <Body>
        {tweet}
      </Body>
    </Container>
  );
};

export default Card;

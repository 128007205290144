import styled from '@emotion/styled';

interface SpacerProps {
    size: Number;
  }
  
  const Spacer = styled.div<SpacerProps>`
    height: ${(props) => props.size.toString()}px;
    min-height: ${(props) => props.size.toString()}px;
  `;

  export default Spacer;
import styled from "@emotion/styled";
import { motion } from "framer-motion";

interface SignInButtonProps {
  onClick?: () => any;
}

const ContainerVariants = {
  idle: { scale: 1 },
  hover: { scale: 1.02 },
  tap: { scale: 1.0 },
};
const Container = styled(motion.button)`
  width: 240px;
  height: 48px;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
`;

const BorderVariants = {
  idle: { backgroundColor: "none", border: "2px solid var(--btnBorder)" },
  hover: { backgroundColor: "var(--blue)", border: "2px solid var(--blue)" },
};
const Border = styled(motion.div)`
  width: 100%;
  height: 100%;
  border: 2px solid var(--btnBorder);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ButtonContentContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const IconContainer = styled.div`
    width: 28px;
    height: 28px;
    margin-left: 18px;
`;

const Text = styled.div`
  font-family: "made_outer_sans_medium";
  font-size: 16px;
  line-height: 20px;
  /* or 56% */
  text-align: center;
  letter-spacing: -0.05em;
  color: var(--btnIconIdle);
  margin-bottom: 2px;
  margin-left: 8px;
`;



const SignInButton = function ({onClick}: SignInButtonProps) {
  return (
    <Container onClick={onClick} whileHover="hover" whileTap="tap" variants={ContainerVariants}>
      <Border variants={BorderVariants}>
        <ButtonContentContainer>
            <IconContainer><img alt="Twitter logo" src="/twitterLogo.svg"/></IconContainer>
            <Text>{"Sign in with Twitter"}</Text>
        </ButtonContentContainer>
      </Border>
    </Container>
  );
};

export default SignInButton;

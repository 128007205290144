import { useParams, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { mark_job_pending } from "../api/routes";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import Spacer from "../components/Spacer";
import GradientAnim from "../components/GradientAnim";

const Background = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: var(--bg);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 800px;
  background: var(--gradientBg);
`;

const Header = styled.div`
  font-family: "made_outer_sans_alt_light";
  font-size: 36px;
  line-height: 45px;
  letter-spacing: -0.05em;
  color: var(--textPrimary);
`;


const Tagline = styled.div`
  font-family: "made_outer_sans_light";
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.05em;
  color: var(--textPrimary);
`;

const SubTagline = styled.div`
  font-family: "made_outer_sans_thin";
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.05em;
  color: var(--textPrimary);
  max-width: 276px;
`;

const GradientContainer = styled.div`
  width: 320px;
  height: 396px;
  position: relative;
`;


const PaymentRedirect = function(){
    const navigate = useNavigate();
    const { status } = useParams();
    const [searchParams] = useSearchParams();
    const job_key = searchParams.get('job_key');

    const {isSuccess, error} = useQuery({
        queryKey: ["mark_job_pending"],
        queryFn: () =>
          fetch(mark_job_pending, {
            method: "POST",
            body: JSON.stringify({ job_key: job_key }),
            headers: {
              "Content-Type": "application/json",
            },
          }).then((res) => res.json()),
        enabled: status === 'success',
    });

    if (error) {
      console.error('Failed to update after payment');
    }

    useEffect(() => {
        if (isSuccess || status === 'cancelled') {
            navigate('/train');
        }
    }, [isSuccess, navigate, status]);
    

    return (
      <Background>
        <Gradient/>
        <Container>
          <Spacer size={72} />
          <Header>Tweet Mirror</Header>
          <Spacer size={24} />
          <Tagline>Thank you for your payment</Tagline>
          <Spacer size={12} />
          <SubTagline>You will be redirected shortly.</SubTagline>
          <Spacer size={12} />
          <GradientContainer>
            <GradientAnim/>
          </GradientContainer>
        </Container>
      </Background>
    )
}

export default PaymentRedirect;
import styled from "@emotion/styled";

const CardGradient1 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    39.87% 40.04% at 50% 50%,
    #0057ff 0%,
    rgba(134, 165, 226, 0) 100%
  );
  animation: movement_one 20s ease-in-out infinite both;

  @keyframes movement_one {
    0% {
      transform: scaleY(0.5) scaleX(0.5) rotate(0deg);
    }
    50% {
      transform: scaleY(0.6) scaleX(0.6) rotate(180deg);
    }
    100% {
      transform: scaleY(0.5) scaleX(0.5) rotate(360deg);
    }
  }
`;

const CardGradient2 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    32.8% 30.53% at 61.66% 38.94%,
    #76089e 0%,
    rgba(207, 156, 225, 0) 100%
  );
  animation: movement_two 15s ease-in-out infinite both;

  @keyframes movement_two {
    0% {
      transform: scaleY(0.5) scaleX(0.5) rotate(0deg);
    }
    50% {
      transform: scaleY(0.6) scaleX(0.6) rotate(180deg);
    }
    100% {
      transform: scaleY(0.5) scaleX(0.5) rotate(360deg);
    }
  }
`;

const CardGradient3 = styled.div`
  position: absolute;
  top: 0;
  left: -25px;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    37% 39.82% at 35.12% 59.29%,
    #2400ff 0%,
    rgba(207, 156, 225, 0) 100%
  );
  animation: movement_three 10s ease-in-out infinite both;

  @keyframes movement_three {
    0% {
      transform: scaleY(0.5) scaleX(0.5) rotate(0deg);
    }
    50% {
      transform: scaleY(0.6) scaleX(0.6) rotate(180deg);
    }
    100% {
      transform: scaleY(0.5) scaleX(0.5) rotate(360deg);
    }
  }
`;

const GradientAnim = function () {
  return (
    <>
      <CardGradient1 />
      <CardGradient2 />
      <CardGradient3 />
    </>
  );
};

export default GradientAnim;

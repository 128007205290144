import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { Auth } from "firebase/auth";
import MirrorLogoStaged from "../components/MirrorLogoStaged";
import NextButton from "../components/NextButton";
import SignInButton from "../components/SignInButton";
import GradientBg from "../components/GradientBg";
import Spacer from "../components/Spacer";
import { useSignInWithTwitter } from "react-firebase-hooks/auth";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  font-family: "made_outer_sans_alt_light";
  color: var(--textPrimary);
  font-size: 36px;
  letter-spacing: -0.05em;
  text-align: center;
`;

const BodyText = styled.p`
  font-family: "made_outer_sans_thin";
  color: var(--textPrimary);
  font-size: 20px;
  letter-spacing: -0.05em;
  line-height: 100%;
  text-align: left;
  max-width: 276px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 12px;
`;

const LogoContainer = styled.div`
  height: 300px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const FooterText = styled.a`
  font-family: "made_outer_sans_thin";
  color: var(--textPrimary);
  font-size: 16px;
  letter-spacing: -0.05em;
  line-height: 100%;
  text-align: center;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

interface HomeProps {
  auth: Auth;
}

const Home = function ({ auth }: HomeProps) {
  const [signInWithTwitter, user] = useSignInWithTwitter(auth);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (user) {
      navigate("/train");
    }
  }, [user, navigate]);

  return (
    <>
      <GradientBg>
        <Container>
          <Spacer size={72} />
          <LogoContainer>
            <MirrorLogoStaged showBird={true} margin={0.4} />
          </LogoContainer>
          <Title>Tweet Mirror</Title>
          <Spacer size={12} />
          {page === 0 && (
            <BodyText>
              <span style={{ fontFamily: "made_outer_sans_black" }}>2022</span>{" "}
              has been an interesting year for{" "}
              <span
                style={{
                  fontFamily: "made_outer_sans_black",
                  color: "#0AC4FF",
                }}
              >
                Twitter
              </span>
              . While the future of the product is clearly{" "}
              <span
                style={{
                  fontFamily: "made_outer_sans_black",
                  color: "#FF0000",
                }}
              >
                uncertain
              </span>
              . It is a product that many{" "}
              <span
                style={{
                  fontFamily: "made_outer_sans_black",
                  color: "#DB00FF",
                }}
              >
                love
              </span>
              .<br></br>
              <br></br> A primary concern that many have - is the profileration
              of bots on the platform. <br></br>
              <br></br>  <span
                style={{
                  fontFamily: "made_outer_sans_regular",
                }}
              >This is a valid concern.</span> <br></br>
              <br></br> But it led me to ask a question.{" "}
              <br></br>
              <br></br> What if I allowed a bot to tweet like me?
            </BodyText>
          )}
          {page === 1 && (
            <BodyText>
              I tried this and felt a strange and eerie sensation. The bot
              shared my vernacular and spoke on similar topics. It felt
              distinctly like me, yet it wasn't.
              <br />
              <br />
              It felt as if I was looking at a distored mirror, a mirage of my
              personality.
              <br />
              <br />
              The experience led me to reflect on what I may be doing in
              alternate universes, the ethics of data collection, and if one
              day, we may need to think about AI as another “living” creature.
              In-silico, but possessing personality, agency, and emotion.
              <br />
              <br /> I invite you to take a look at your tweet mirror. Sign in
              to train an AI to tweet like you.
            </BodyText>
          )}
          <Spacer size={24} />
          {page === 0 && <NextButton onClick={() => setPage(1)} />}
          {page === 1 && <SignInButton onClick={() => signInWithTwitter()} />}
          <Spacer size={72} />
          <Footer>
            <FooterText href="/terms.html">Terms</FooterText>
            <FooterText href="/privacy.html">Privacy</FooterText>
            <FooterText href="mailto:contact@tweetmirror.ai">Contact</FooterText>
          </Footer>
          <Spacer size={24} />
        </Container>
      </GradientBg>
    </>
  );
};

export default Home;

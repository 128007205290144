import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { create_training_job } from "../api/routes";
import Spacer from "../components/Spacer";
import RegularButton from "../components/RegularButton";

const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 276px;
`;

const QuestionsItem = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

interface CollapseProps {
  rotated: boolean;
}
const CollapseIcon = styled.img<CollapseProps>`
  width: 16px;
  height: 16px;
  cursor: pointer;
  transform-origin: center center;
  transform: ${(props) => (props.rotated ? "rotate(90deg)" : "rotate(0deg")};
`;

const QuestionText = styled.p`
  font-family: "made_outer_sans_medium";
  font-size: 16px;
  line-height: 200%;
  letter-spacing: -0.05em;
  color: var(--textPrimary);
  margin-top: -9px;
  margin-bottom: 0px;
  margin-left: 8px;
  cursor: pointer;
`;

const AnswerText = styled.p`
  font-family: "made_outer_sans_thin";
  font-size: 16px;
  line-height: 125%;
  letter-spacing: -0.05em;
  margin-left: 24px;
  margin-top: 8px;
  color: var(--textPrimary);
`;

interface PretrainingProps {
  username: string;
  disabled: boolean;
}

const Pretraining = function ({ username, disabled }: PretrainingProps) {
  const [q1Enabled, setq1Enabled] = useState(false);
  const [q2Enabled, setq2Enabled] = useState(false);
  const [q3Enabled, setq3Enabled] = useState(false);

  // Will call create_training_job endpoint on creation, which will take you to checkout
  const {isSuccess, isFetching, data, refetch } = useQuery({
    queryKey: ["create_training_job"],
    queryFn: () =>
      fetch(create_training_job, {
        method: "POST",
        body: JSON.stringify({ username: username }),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => res.json()),
    enabled: false,
    cacheTime: 0,
  });

  useEffect(() => {
    if (isSuccess) {
      window.location.href = data.redirect_url;
    }
  }, [isSuccess, data]);

  return (
    <>
      <RegularButton
        label={"Get your Tweet Mirror - $8"}
        onClick={() => refetch()}
        loading={isFetching}
        enabled={!disabled}
      />
      <Spacer size={24} />
      <QuestionsContainer>
        <QuestionsItem onClick={() => setq1Enabled((val) => !val)}>
          <CollapseIcon rotated={q1Enabled} src="/collapsedIcon.svg" />
          <QuestionText>What do I get?</QuestionText>
        </QuestionsItem>
        {q1Enabled && (
          <AnswerText>
            ○ 100 AI Generated Tweets based on your Twitter history.
            <br />
            <br />○ An analysis of your Twitter history including datapoints
            such as what topics you mention most frequently and which of your
            tweets are liked the most. This information is also used to train the AI.
          </AnswerText>
        )}
      </QuestionsContainer>
      <Spacer size={12} />
      <QuestionsContainer>
        <QuestionsItem onClick={() => setq2Enabled((val) => !val)}>
          <CollapseIcon rotated={q2Enabled} src="/collapsedIcon.svg" />
          <QuestionText>Why does this cost money?</QuestionText>
        </QuestionsItem>
        {q2Enabled && (
          <AnswerText>
            Tweet Mirror trains a Large Language Model on your Twitter history
            using a process known as fine-tuning. Training the model uses
            expensive Graphics Processing Units (GPUs). As a result, this service would
            be very costly to give away for free.
            <br />
            <br />
            I'd love to offer Tweet Mirror for free if it were possible!
          </AnswerText>
        )}
      </QuestionsContainer>
      <Spacer size={12} />
      <QuestionsContainer>
        <QuestionsItem onClick={() => setq3Enabled((val) => !val)}>
          <CollapseIcon rotated={q3Enabled} src="/collapsedIcon.svg" />
          <QuestionText>Will it work for any language?</QuestionText>
        </QuestionsItem>
        {q3Enabled && (
          <AnswerText>
            No, Tweet Mirror only works if the majority of your tweets are in Englsh.
          </AnswerText>
        )}
      </QuestionsContainer>
    </>
  );
};

export default Pretraining;

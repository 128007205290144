import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { User } from "firebase/auth";
import { useQuery } from "@tanstack/react-query";
import {
  get_tweet_count,
  get_tweet_generations,
} from "../api/routes";
import { useNavigate } from "react-router-dom";
import GradientBg from "../components/GradientBg";
import Spacer from "../components/Spacer";
import GradientAnim from "../components/GradientAnim";
import CardFlipper from "../components/CardFlipper";
import Pretraining from "../components/Pretraining";
import { MIN_HEIGHT } from "../components/Card";
import GeneratedMirror from "../components/GeneratedMirror";
import { motion } from "framer-motion";

const MIN_TWEETS = 500;
interface TrainProps {
  user: User;
}

const Header = styled.div`
  font-family: "made_outer_sans_alt_light";
  font-size: 36px;
  line-height: 45px;
  letter-spacing: -0.05em;
  color: var(--textPrimary);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Tagline = styled.div`
  font-family: "made_outer_sans_light";
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.05em;
  color: var(--textPrimary);
`;

const SubTagline = styled.div`
  font-family: "made_outer_sans_thin";
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.05em;
  color: var(--textPrimary);
  max-width: 276px;
`;

const CardContainer = styled.div`
  width: 320px;
  min-height: ${MIN_HEIGHT}px;
  position: relative;
`;

const GradientContainer = styled.div`
  width: 320px;
  height: 396px;
  position: relative;
`;

const CardInnerContainer = styled.div`
  position: relative;
  padding-top: 12px;
`;

const FullScreenCoverVariants = {
  off: {
    opacity: 0,
  },
  on: {
    opacity: 1,
  },
};

const FullScreenCover = styled(motion.div)`
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0;
  pointer-events: none;
  width: 100vw;
  height: 100vh;
  background-color: var(--mirrorBg1);
`;

const Train = function ({ user }: TrainProps) {
  // @ts-ignore This property isn't in the typing but is internal and we need it
  var username = user["reloadUserInfo"]["screenName"];
  // @ts-ignore This property isn't in the typing but is internal and we need it
  var photoUrl = user["photoURL"];
  var displayName = user["displayName"];
  //username = "wowitsmrinal";
  const navigate = useNavigate();
  const [transitionToMirror, setTransitionToMirror] = useState(false);

  useEffect(() => {
    const transition = function(){
      navigate('/mirror');
    }
    var tId: number;
    if (transitionToMirror){
      tId = window.setTimeout(transition, 500);
    }
    return () => tId ? clearTimeout(tId) : undefined;
  }, ([transitionToMirror, navigate]));

  // Call API retrieve their tweet count
  const {
    isLoading: isLoadingCount,
    error: errorCount,
    data: dataCount,
  } = useQuery({
    queryKey: ["get_tweet_count"],
    queryFn: () =>
      fetch(get_tweet_count, {
        method: "POST",
        body: JSON.stringify({ username: username }),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => res.json()),
  });

  // Call API retrieve their generations, if any
  const {
    isLoading: isLoadingGenerations,
    error: errorGenerations,
    data: dataGenerations,
  } = useQuery({
    queryKey: ["get_tweet_generations"],
    queryFn: () =>
      fetch(get_tweet_generations, {
        method: "POST",
        body: JSON.stringify({ username: username }),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => res.json()),
    cacheTime: 0,
  });

  var loading = true;
  var tagline = "";
  var subTagline = "";
  if (!isLoadingGenerations && !errorGenerations) {
    if (dataGenerations.has_generations) {
      loading = false;
      tagline = "Your mirror is ready";
      subTagline = "Find out how an AI trained on you sounds.";
    } else if (dataGenerations.is_training) {
      loading = false;
      tagline = "Training in Progress";
      subTagline =
        "Creating your tweet mirror. Training usually takes 15 minutes to 1 hour.";
    } else if (!isLoadingCount && !errorCount) {
      loading = false;
      tagline = !dataCount.is_protected ? `You have ${dataCount.count.toLocaleString("en-US", {
        maximumFractionDigits: 0,
      })} tweets` + (dataCount.count >= MIN_TWEETS ? '!' : '') : 'Your tweets are protected';
      if (dataCount.is_protected){
        subTagline = "Tweet Mirror can only train an AI on publicly accessible tweets."
      } else {
        subTagline = dataCount.count >= MIN_TWEETS ? "Here are some of the tweets we will train the AI with." : "You don't have enough tweets to train an AI! Tweet Mirror needs at least 500 tweets in order to train.";
      }
    }
  }

  if (errorGenerations || errorCount) {
    // TODO: handle error
  }

  return (
    <>
      <GradientBg>
        <Container>
          <Spacer size={72} />
          <Header>Tweet Mirror</Header>
          <Spacer size={24} />
          {!loading && <Tagline>{tagline}</Tagline>}
          <Spacer size={12} />
          {!loading && <SubTagline>{subTagline}</SubTagline>}
          <Spacer size={12} />
          {(loading || dataGenerations.is_training) && (
            <GradientContainer>
              <GradientAnim />
            </GradientContainer>
          )}
          {!isLoadingCount &&
            !isLoadingGenerations &&
            !dataGenerations.has_generations &&
            !dataGenerations.is_training &&
            !dataCount.is_protected && (
              <CardContainer>
                {!loading && (
                  <CardInnerContainer>
                    <CardFlipper
                      username={username}
                      handle={displayName ?? "Tweeter"}
                      imgUrl={photoUrl ?? "Tweeter"}
                      tweets={dataCount["previewTweets"]}
                    />
                  </CardInnerContainer>
                )}
              </CardContainer>
            )}
          {(() => {
            if (isLoadingGenerations || isLoadingCount) {
              return;
            }
            if (dataGenerations.is_training) {
              return <Training />;
            } else if (dataGenerations.has_generations) {
              return (
                <GeneratedMirror
                  tweets={dataGenerations.tweets}
                  profileImgSrc={photoUrl}
                  onClick={() => setTransitionToMirror(true)}
                />
              );
            } else {
              return (
                <>
                  <Spacer size={48} />
                  {!dataCount.is_protected && <Pretraining username={username} disabled={dataCount.count < MIN_TWEETS}/>}
                </>
              );
            }
          })()}
        </Container>
        <Spacer size={72}/>
      </GradientBg>
      <FullScreenCover variants={FullScreenCoverVariants} initial="off" animate={transitionToMirror ? "on" : "off"}/>
    </>
  );
};

const Training = function () {
  return <div />;
};

export default Train;

import { Navigate } from "react-router-dom";
import { User } from "firebase/auth";
import React from "react";

interface RequireAuthProps {
  user: User | null | undefined;
  authLoading: boolean;
  children: JSX.Element;
}

function RequireAuth({ user, children, authLoading }: RequireAuthProps) {
  if (authLoading) {
    return null;
  }
  return user ? children : <Navigate to="/" replace />;
}

export default RequireAuth;

import styled from "@emotion/styled";
import { useQuery } from "@tanstack/react-query";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { get_tweet_generations } from "../api/routes";
import Card from "../components/Card";
import Spacer from "../components/Spacer";

const Background = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: var(--bg);
`;

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Container = styled.div`
  height: 100%;
  width: 390px;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 24px;
  z-index: 2;
`;

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 800px;
  background: var(--gradientBg);
  z-index: 1;
`;

const Logo = styled.div`
  font-family: "made_outer_sans_alt_light";
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.05em;
  color: var(--textPrimary);
  margin-top: 60px;
`;

const Title = styled.div`
  font-family: "made_outer_sans_light";
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.05em;
  color: var(--textPrimary);
`;

const UsernameSpan = styled.span`
  color: var(--textUsername);
`;

const CardVariants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
};
const CardContainer = styled(motion.div)`
  width: 320px;
  position: relative;
  margin-bottom: 12px;
  margin-left: auto;
  margin-right: auto;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  position: relative;
`;

const TweetContainer = styled(motion.div)`
  position: absolute;
  right: 12px;
  bottom: 0px;
`;

const Generations = function () {
  var { username } = useParams();

  // Call API retrieve their generations
  const { isSuccess, error, data } = useQuery({
    queryKey: ["get_tweet_generations"],
    queryFn: () =>
      fetch(get_tweet_generations, {
        method: "POST",
        body: JSON.stringify({ username: username }),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => res.json()),
    cacheTime: 0,
  });

  if (error) {
    console.error("Failed to fetch generations");
  }

  useEffect(() => {
    //@ts-ignore
    window.twttr = (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0],
        //@ts-ignore
        t = window.twttr || {};
      if (d.getElementById(id)) return t;
      js = d.createElement(s);
      js.id = id;
      //@ts-ignore
      js.src = "https://platform.twitter.com/widgets.js";
      //@ts-ignore
      fjs.parentNode.insertBefore(js, fjs);

      t._e = [];
      //@ts-ignore
      t.ready = function (f) {
        t._e.push(f);
      };

      return t;
    })(document, "script", "twitter-wjs");
  }, []);

  return (
    <Background>
      <PageContainer>
        <Gradient />
        <Container>
          <HeaderContainer>
            <Logo>Tweet Mirror</Logo>
            <TweetContainer
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.2 } }}
            >
              <a
                className="twitter-share-button"
                href="https://twitter.com/intent/tweet?text=I%20trained%20an%20AI%20to%20talk%20like%20me!"
              >
                Tweet
              </a>
            </TweetContainer>
          </HeaderContainer>
          <Spacer size={48} />
          {(isSuccess || error) && (
            <Title>
              <UsernameSpan>@{username}</UsernameSpan>
              {isSuccess && data.has_generations && !error
                ? "'s generated tweets"
                : " hasn't created their tweet mirror"}
            </Title>
          )}
          <Spacer size={24} />
          {isSuccess &&
            data.has_generations &&
            data.tweets.map((tweet: string) => (
              <CardContainer
                key={tweet}
                variants={CardVariants}
                initial="initial"
                animate="animate"
              >
                <Card
                  username={data.display_name ?? "Tweeter"}
                  handle={username ?? "Tweeter"}
                  imgUrl={data.profile_image_url ?? ""}
                  tweet={tweet}
                  enforceMinHeight={false}
                  stack={false}
                />
              </CardContainer>
            ))}
          <Spacer size={72} />
        </Container>
      </PageContainer>
    </Background>
  );
};

export default Generations;

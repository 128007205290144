import styled from "@emotion/styled";
import { motion } from "framer-motion";

interface RegularButtonProps {
  onClick?: () => any;
  label: string;
  loading?: boolean;
  enabled?: boolean;
}

const ContainerVariants = {
  idle: { scale: 1 },
  hover: { scale: 1.05 },
  tap: { scale: 0.98 },
  loading: { scale: 1 },
  disabled: { scale: 1 },
};
const Container = styled(motion.button, {
  shouldForwardProp: (prop) => prop !== "enabled",
})<{ enabled: boolean }>`
  width: 264px;
  height: 48px;
  border: none;
  background: none;
  padding: 0;
  cursor: ${(props) => (props.enabled ? "pointer" : "not-allowed")};
`;

const BorderVariants = {
  idle: {
    backgroundColor: "rgba(0,0,0,0)",
    border: "2px solid var(--btnBorder)",
  },
  hover: {
    backgroundColor: "var(--btnBorder)",
    border: "2px solid var(--btnBorder)",
  },
  tap: {
    backgroundColor: "var(--btnBorder)",
    border: "2px solid var(--btnBorder)",
  },
  loading: {
    backgroundColor: "rgba(0,0,0,0)",
    border: "2px solid var(--btnBorder)",
  },
  disabled: {
    backgroundColor: "rgba(0,0,0,0)",
    border: "2px solid var(--btnBorder)",
  },
};
const Border = styled(motion.div)`
  width: 100%;
  height: 100%;
  border: 2px solid var(--btnBorder);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ButtonContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const TextVariants = {
  idle: { color: "var(--btnIconIdle)" },
  tap: { color: "var(--btnIconHover)" },
  hover: { color: "var(--btnIconHover)" },
  disabled: { color: "var(--btnIconIdle)" },
};
const Text = styled(motion.div)`
  font-family: "made_outer_sans_medium";
  font-size: 16px;
  line-height: 20px;
  /* or 56% */
  text-align: center;
  letter-spacing: -0.05em;
  color: var(--btnIconIdle);
`;

const LoadingSpinner = styled.div`
  display: inline-block;
  width: 48px;
  height: 48px;

  ::after {
    content: " ";
    display: block;
    width: 24px;
    height: 24px;
    margin: 10px;
    border-radius: 50%;
    border: 2px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: spin 1.2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const RegularButton = function ({
  onClick,
  label,
  loading = false,
  enabled = true,
}: RegularButtonProps) {
  const _enabled = !loading && enabled;
  var hoverVariant = "hover";
  var tapVariant = "tap";

  if (loading) {
    hoverVariant = "loading";
    tapVariant = "loading";
  }
  if (!enabled) {
    hoverVariant = "disabled";
    tapVariant = "disabled";
  }
  return (
    <Container
      onClick={() => (_enabled && onClick ? onClick() : null)}
      whileHover={hoverVariant}
      whileTap={tapVariant}
      variants={ContainerVariants}
      enabled={_enabled}
    >
      <Border variants={BorderVariants}>
        <ButtonContentContainer>
          {!loading && <Text variants={TextVariants}>{label}</Text>}
          {loading && <LoadingSpinner />}
        </ButtonContentContainer>
      </Border>
    </Container>
  );
};

export default RegularButton;

import { useTexture } from "@react-three/drei";

const fragmentShader = `
  varying vec3 Normal;
  varying vec3 Position;
  varying vec2 vUv;
  uniform sampler2D tex;

  void main() {
    float distance = distance(vUv, vec2(0.5)) * 2.0;
    float alpha = mix(0.0, 1.0, 1.0-distance);
    vec3 color = texture2D(tex, vUv).xyz;
    gl_FragColor = vec4(color, alpha);
}`;

const vertexShader = `
  varying vec2 vUv;
  varying vec3 Normal;
  varying vec3 Position;

  void main() {
    Normal = normalize(normalMatrix * normal);
    Position = vec3(modelViewMatrix * vec4(position, 1.0));
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`;

interface MirrorImageProps {
  imgSrc: string;
}
const MirrorImage = function ({ imgSrc }: MirrorImageProps) {
  const imgTex = useTexture(imgSrc ?? "/smallTransparent.png");
  const data = {
    fragmentShader,
    vertexShader,
    uniforms : {
        tex: {
            type: "t",
            value: imgTex,
        }
    }
  };

  return (
    <mesh
      position={[0.15, 0, 0]}
      rotation={[0, Math.PI / 2, 0]}
      scale={[1.2, 3, 1]}
    >
      <circleBufferGeometry args={[0.3, 32]} />
      <shaderMaterial attach="material" transparent={true} {...data} />
    </mesh>
  );
};

export default MirrorImage;

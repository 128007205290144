import styled from "@emotion/styled";
import MirrorLogoStaged from "./MirrorLogoStaged";
import RegularButton from "./RegularButton";
import Spacer from "./Spacer";

interface GeneratedProps {
  tweets: string[];
  profileImgSrc: string | null;
  onClick?: () => any;
}

const Container = styled.div`
  width: 100vw;
  height: 500px;
  margin-top: -24px;
  margin-left: 10px;
`;

const GeneratedMirror = function ({ tweets, profileImgSrc, onClick }: GeneratedProps) {
  return (
    <>
      <Container>
        <MirrorLogoStaged
          showBird={false}
          margin={0.4}
          imgSrc={profileImgSrc ?? undefined}
        />
      </Container>
      <Spacer size={24} />
      <RegularButton onClick={onClick} label={"Look into your mirror"}/>
    </>
  );
};

export default GeneratedMirror;
